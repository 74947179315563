

.result__dashboard {
  display: flex;
  flex-direction: column;
}

.dashboard-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: serif;
  margin: auto;
}

.dashboard-title h3 {
  font-size: 25px;
  color: red;
  display: flex;
  font-weight: 700;
}

.live h3 {
  margin: auto;
}

.dot {
  display: flex;
  margin: auto 0.5rem;
}

.live_side h2 {
  color: blue;
  margin: auto 0.2rem;
  font-size: 25px;
  display: flex;
  align-items: center;
  font-weight: 700;
}

/*------------banners----------*/

.description {
  display: flex;
  flex-direction: column;
}

.banner {
  background-color: rgb(252, 252, 71);
  margin: auto;
  padding: 1rem;
  width: 100%;
}

.banner p {
  text-align: center;
  margin: auto;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.biglogo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto auto 2rem;
  padding-top: 1rem;
}

.callbanner {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0.5rem;
  background-color: rgb(252, 252, 71);
  width: 100%;
}

.callbanner p {
  text-align: center;
  margin: auto;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.callbanner h4 {
  margin: auto;
  color: blue;
  font-weight: 700;
}

.twobuttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.twobuttons button {
  background-color: rgb(25, 124, 0);
  border-radius: 5px;
  box-shadow: 0 1px 5px rgb(37, 59, 0);
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 1rem;
  color: white;
}

.twobuttons button:hover {
  background-color: rgb(16, 156, 16);
}

.app_install {
  display: flex;
  width: 100%;
}

.app_install a {
  text-decoration: none;
  width: 100%;
}

.app_install button {
  width: 100%;
  justify-content: center;
  margin: 2rem auto 0 auto;
  background-color: green;
  color: white;
  padding: 0.5rem;
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgb(0, 99, 0);
}

.app_install button:hover {
  background-color: rgb(16, 156, 16);
}

/*------------alltables-------*/

.month {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 2rem auto 0 auto;
  background-color: #2d3e92;
  color: rgb(252, 252, 71);
}

/*----------timetable------*/

.timetable__rules {
  display: flex;
  flex-direction: column;
  margin: 2rem auto auto;
}

.timetable__rules h2 {
  font-weight: 600;
  margin: auto auto 0.5rem;
}

.timetable__rules p {
  font-size: 20px;
  margin: auto auto 1rem;
}

.timetable__rules span {
  font-weight: 700;
  color: #2d3e92;
}

.timetable {
  width: 70%;
  margin: auto;
}

/*--------------------------alltable--------------*/

.all_table {
  display: flex;
  flex-direction: column;
}

.alltable__title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem auto 0.2rem auto;
  background-color: #2d3e92;
  color: rgb(252, 252, 71);
}

.alltable__title h3 {
  font-size: 25px;
  font-weight: 600;
}

Table th {
  background-color: #2d3e92 !important;
  color: white;
}

Table td {
  background-color: #ebedfc !important;
  color: black;
}

/*Animation*/
.marquee_container {
  background-color: yellow;
  width: 5rem;
  margin: 1rem 0.2rem;
  /* margin-top: 1rem;
    padding-top: 0.1rem; */
}

.marquee_tag {
  animation: blinkingText 1.2s infinite;
  text-align: center;
  font-size: larger;
  font-family: Arial, serif;
  font-weight: bolder;
}

@keyframes blinkingText {
  0% {
    color: #000000;
  }
  49% {
    color: #ff0000;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #ff0000;
  }
}

/*install button animation---*/

.marquee_container {
  background-color: yellow;
  width: 5rem;
  margin: 1rem 0.2rem;
  /* margin-top: 1rem;
    padding-top: 0.1rem; */
}

.marquee_button {
  animation: blinkingButton 1.2s infinite;
  width: 100%;
  justify-content: center;
  margin: 2rem auto 0 auto;
  background-color: green;
  color: white;
  padding: 0.5rem;
  border: none;
  outline: none;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgb(0, 99, 0);
}

@keyframes blinkingButton {
  0% {
    color: white;
    background-color: red;
    box-shadow: 0 2px 5px rgb(167, 0, 0);
  }
  49% {
    color: yellow;
    background-color: rgb(0, 0, 182);
    box-shadow: 0 2px 5px rgb(15, 0, 71);
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: yellow;
    background-color: rgb(0, 0, 182);
    box-shadow: 0 2px 5px rgb(15, 0, 71);
  }
}

/*------------------responsive 550px-------------------------*/

@media screen and (max-width: 550px) {
  .biglogo {
    width: 100%;
    margin: auto auto 1rem;
    justify-content: center;
    padding-top: 1rem;
  }

  .biglogo img {
    width: 100%;
    height: auto;
    margin: auto;
    justify-content: center;
    max-width: 100%;
    object-fit: fill;
  }
  
}

/*------------------responsive 350px-------------------------*/

@media screen and (max-width: 350px) {
  .live_side h2 {
    color: blue;
    margin-top: 0;
    font-size: 20px;
  }

  .biglogo {
    width: 100%;
    margin: auto auto 1rem;
    justify-content: center;
    padding-top: 1rem;
  }

  .biglogo img {
    width: 100%;
    height: auto;
    margin: auto;
    justify-content: center;
    max-width: 100%;
    object-fit: fill;
  }
  
}
