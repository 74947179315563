.result__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2D3E92;
    color: white;
    box-shadow: 0 1px 5px #b7bace;
    height: 3rem;
    /* padding: 1.5rem 6rem; */
  }
  
  .result__footer-links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .result__footer-links_container a {
    text-decoration: none;
    color: white;
  }
  
  
  
  .result__footer-links_logo p{
      align-items: center;
      margin: auto;
  }
  
  
  
  .result__footer-links_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  
  .result__footer-links_container p {
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-transform: capitalize;
  
    margin: 0 1rem;
    cursor: pointer;
  }
  
  
  
  /*----------1050-------------------------------------------------*/
  
  @media screen and (max-width: 1050px) {
    .result__footer-links_container p{
      font-size: 18px;
      line-height: 21px;
      margin: 0 1rem;
    }
  }
  
  /*----------700-------------------------------------------*/
  
  @media screen and (max-width: 700px) {
    .result__footer {
      padding: 1rem 4rem;
    }
    .result__footer-links_container p {
      font-size: 15px;
      line-height: 18px;
      margin: 0 1rem;
    }
    
  }
  
  /*----------550-----------------------------------------------*/
  
  @media screen and (max-width: 550px) {
    .result__footer {
      height: 0.5rem;
    }
    .result__footer-links_container p{
      font-size: 12px;
      line-height: 9px;
      margin: 0 0.7rem;
    }

  }
  
   /*----------350-----------------------------------------------*/
  
   @media screen and (max-width: 350px) {
    
    
    .result__footer-links_container p{
      font-size: 12px;
      line-height: 9px;
      margin: 0 0.7rem;
    }

  }
  
  