.timetable__title{
    width: 100%;
    background-color: #2D3E92;
    color: rgb(252, 252, 71);
    display: flex;
    justify-content: center;
    
}

.timetable__title h3{
    margin: auto;
    padding: 0.2rem;
}

td{
    text-align: center;
    font-weight: 700;
}

th{
    text-align: center;
}


