/* .result__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #daddec;
  box-shadow: 0 1px 5px #b7bace;
  height: 4rem;
  
}

.result__navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  text-decoration: none;

  color: black;
}

a:hover {
  color: #FD9600;
}

.active {
  color: #2D3E92;
}

.result__navbar-links_logo {
  margin-right: 2rem;
}

.result__navbar-links_logo img {
  width: 70%;
  height: auto;
}

.result__navbar-links_container {
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
}

.result__navbar-links_container p {
  color: black;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.agent_login_btn {
  background-color: darkblue;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgb(9, 0, 59);
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  margin: 1rem;
  color: white;
} */

/*----------1050-------------------------------------------------*/

/* @media screen and (max-width: 1050px) {
  .result__navbar-links_container p,
  .result__navbar-sign p {
    font-size: 18px;
    line-height: 21px;
    margin: 0 1rem;
  }
} */

/*----------700-------------------------------------------*/

/* @media screen and (max-width: 700px) {
  .result__navbar {
    padding: 1rem 4rem;
  }
  .result__navbar-links_container p,
  .result__navbar-sign p {
    font-size: 15px;
    line-height: 18px;
    margin: 0 1rem;
  }
  .result__navbar-links_logo img {
    width: 80%;
    height: auto;
  }
} */

/*----------550-----------------------------------------------*/

/* @media screen and (max-width: 550px) {
  .result__navbar {
    padding: 0.1rem;
    height: 2rem;

  }



  .result__navbar-links_container p,
  .result__navbar-sign p {
    font-size: 15px;
    line-height: 12px;
    margin: 0 0.7rem;
  }
  .result__navbar-links_logo img {
    width: 70%;
    height: auto;
  }
} */

/*-----------------------360px---------------------------------*/

/* @media screen and (max-width: 360px) {
  .result__navbar {
    padding: 0.2rem;
    height: 2rem;
  }
  .result__navbar-links_container p,
  .result__navbar-sign p {
    font-size: 14px;
    line-height: 9px;
    margin: 0 0.4rem;
  }

  .result__navbar-links_logo {
    margin-right: 0.5rem;
  }
} */

/*---------new----------------*/

.result__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #daddec;
  box-shadow: 0 1px 5px #b7bace;
  height: 5rem;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 50;
  padding: 1.5rem 6rem;

  /* padding: 1.5rem 6rem; */
}

.result__navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a {
  text-decoration: none;

  color: black;
}

a:hover {
  color: #fd9600;
}

.active {
  color: #2d3e92;
}

.result__navbar-links_logo {
  margin-right: 2rem;
  
}

.result__navbar-links_logo img {
  width: 70%;
  height: auto;
}

.result__navbar-links_container {
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;
}

.result__navbar-links_container p {
  color: black;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.result__navbar-links_container button {
  background-color: darkblue;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgb(9, 0, 59);
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  color: white;
}

/*----------1050-------------------------------------------------*/

@media screen and (max-width: 1050px) {
  .result__navbar-links_container p {
    font-size: 18px;
    line-height: 21px;
    margin: 0 1rem;
  }
}

/*----------830-----------------------------------------------*/

@media screen and (max-width: 830px) {
  .result__navbar-links_container p {
    font-size: 17px;
    line-height: 18px;
    margin: 0 0.5rem;
  }
}

/*----------770-------------------------------------------*/
@media screen and (max-width: 770px) {
  .result__navbar {
    padding: 1rem 3rem;
    height: 4.5rem;
  }

  .result__navbar-links_logo {
    margin-right: 0.1rem;
    width: 40%;
  }

  .result__navbar-links_logo img {
    width: 80%;
  }

  .result__navbar-links_container{
    flex: 2;
  }

  .result__navbar-links_container p {
    font-size: 15px;
    line-height: 14px;
  }
}

/*----------532-----------------------------------------------*/
@media screen and (max-width:532px){
  .result__navbar {
    padding: 1rem 2rem;
    height: 4rem;
  }

  .result__navbar-links_logo {
    margin-right: 0.3rem;
    width: 30%;
  }

  .result__navbar-links_logo img {
    width: 100%;
  }
  .result__navbar-links_container p {
    font-size: 14px;
    line-height: 11px;
    margin: 0 0.5rem;
  }
}
/*-----------------------420px---------------------------------*/

@media screen and (max-width:420px){
  .result__navbar {
    padding: 1rem 0.5rem;
  }


  .result__navbar-links_container p {
    font-size: 12px;
    line-height: 13px;
    margin: 0 0.4rem;
  }
}
