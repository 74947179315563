.main__table {
  display: flex;
  flex-direction: column;
}

.table__title {
  display: flex;
  justify-content: center;
  margin: 2rem auto 0.2rem auto;
  background-color: #2d3e92;
  color: rgb(252, 252, 71);
}

.table__title h3 {
  font-size: 25px;
  font-weight: 600;
}

Table th {
  background-color: #2d3e92 !important;
  color: white;
}

Table td {
  background-color: #ebedfc !important;
  color: black;
}
